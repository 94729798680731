import { changeTagName } from '../util/scripts';

const replaceH1 = () => {
    console.log('replaceH1');
    const h1 = Array.from(document.querySelectorAll('h1[class^="heroTitle"]'));

    if (h1.length > 0) {
        h1.forEach((el) => {
            changeTagName(el, 'span');
        });
    }
};

const componentDidUpdate = (args, callback) => {
    callback(...args);
    replaceH1();
};

export default {
    'Route/HomePage/Container': {
        'member-function': {
            componentDidUpdate
        }
    }
};
